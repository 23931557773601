// extracted by mini-css-extract-plugin
export var video = "Root-module--video--63063";
export var videoMap = "Root-module--videoMap--ab43a";
export var news = "Root-module--news--a13ba";
export var text = "Root-module--text--22345";
export var title = "Root-module--title--7ae86";
export var content = "Root-module--content--e4674";
export var form = "Root-module--form--b5951";
export var wrapper = "Root-module--wrapper--4ace6";
export var subtitle = "Root-module--subtitle--aa004";
export var inputs = "Root-module--inputs--13726";
export var links = "Root-module--links--71f4a";
export var item = "Root-module--item--a3a59";
export var icon = "Root-module--icon--a055b";
export var social = "Root-module--social--fca2e";