/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext, useEffect } from 'react'
import { useWeterVisualizations, useWeterNews, useWeterPatents } from '@graphql'

import * as styles from './Root.module.scss'

import {
  Info,
  Schema,
  OldSolutions,
  Technology,
  Comparison,
  Opportunities,
  Goal,
} from '@components/weter'

import {
  Main,
  Important,
  VideoPresentation,
  Visualization,
  Author,
  Map,
  Patents,
  Committee,
  Streams,
  Footer,
  News,
  Future,
  StockCharts,
  Invest,
  Header,
} from '@components/sections'

import { Modal } from '@components/App/Modal'
import { Button, Input, PhoneInput, Typography, EmbedVideo } from '@UIKit'
import { AppContext } from '@components/App'
import { Content, getContent } from '@utils'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'

import { getNewsData } from '../../../../../api/news'
import { Script } from 'gatsby'

import threads from '../../../../assets/images/weter/socialIcon/Threads.svg'
import twitter from '../../../../assets/images/weter/socialIcon/Twitter.svg'
import telegram from '../../../../assets/images/weter/socialIcon/Telegram.svg'
import instagram from '../../../../assets/images/weter/socialIcon/Instagram.svg'
import facebook from '../../../../assets/images/weter/socialIcon/Facebook.svg'
import linkedin from '../../../../assets/images/weter/socialIcon/LinkedIn.svg'
import youtube from '../../../../assets/images/weter/socialIcon/YouTube.svg'
import LogoIcon from '../../../../assets/images/weter.inline.svg'
import BrandIcon from '../../../../assets/images/weter/weter.inline.svg'
import { getApi } from '../../../../../api/_utils'
import { streams } from '../../../share/consts/streams'

import mapMp4 from '@assets/videos/weter/map.mp4'
import mapWebm from '@assets/videos/weter/map.webm'

const logoIconProps = <LogoIcon width={40} height={40} />
const brandIconProps = <BrandIcon />

const maps = {
  iframe: (
    <iframe
      title="weter"
      src="https://embed.windy.com/embed2.html?lat=25.2048&lon=55.2708&zoom=3&level=surface&overlay=wind&menu=false&message=&marker=&calendar=&pressure=&type=map&location=coordinates&detail=&detailLat=41.9028&detailLon=12.4964&metricWind=m%2Fs&metricTemp=%C2%B0C&radarRange=-1"
      width="100%"
      height="100%"
      frameBorder="0"
    />
  ),
  video: (
    <video className={styles.videoMap} autoPlay playsInline loop muted>
      <source src={mapWebm} type="video/webm" />
      <source src={mapMp4} type="video/mp4" />
    </video>
  ),
}

const videos = {
  ru: 'URt5RjFAYaA',
  en: '2ua3RjBidkE',
}

export const Root = () => {
  const { langCode } = useContext(AppContext)
  const news = useWeterNews()
  const patents = useWeterPatents()
  // const [userCountry, setUserCountry] = useState('')
  // const [phoneValue, setPhoneValue] = useState('')

  const [presentationModal, setPresentationModal] = useState(false)
  const [newsModal, setNewsModal] = useState(false)
  // const [formModal, setFormModal] = useState(false)

  const [selectedNews, setSelectedNews] = useState(0)
  const [selectedDescription, setSelectedDescription] = useState<Content[]>([])

  useEffect(() => {
    const getDescription = async () => {
      const description: Content[] = await getNewsData(news[selectedNews].id).then(({ data }) => data.content)
      setSelectedDescription(description)
    }

    getDescription()
  }, [selectedNews])

  const nextNews = () => {
    setSelectedNews((state) => (state + 1) % news.length)
  }
  const prevNews = () => {
    setSelectedNews((state) => (state - 1 < 0 ? news.length - 1 : state - 1))
  }

  const openPresentationModal = () => {
    setPresentationModal(true)
  }
  const openNewsModal = () => {
    setNewsModal(true)
  }

  // const openFormModal = () => {
  //   setFormModal(true)
  // }

  // const handleForm = async () => {
  //   const { data } = await fetch('https://api.tiaglin.com/application/register/start', {
  //     method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //     // mode: 'cors', // no-cors, *cors, same-origin
  //     // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //     // credentials: 'same-origin', // include, *same-origin, omit
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     // redirect: 'follow',
  //     // referrerPolicy: 'no-referrer',
  //     body: JSON.stringify({
  //       context: 'phone',
  //       lang: langCode,
  //       phone: phoneValue,
  //     }),
  //   }).then((res) => res.json())
  //   await localStorage.setItem('weter.landing.form.response', JSON.stringify(data))
  // }

  // useEffect(() => {
  //   const detectUserCountry = async () => {
  //     const userCountry = await fetch('https://api.tiaglin.com/application/defaults/user')
  //       .then((data) => data.json())
  //       .then(({ data }) => data.country.toLowerCase())

  //     setUserCountry(userCountry)
  //   }

  //   detectUserCountry()
  // }, [])

  const linksData = [
    {
      icon: threads,
      content: 'Threads',
      url: 'https://www.threads.net/@weter_world',
    },
    {
      icon: twitter,
      content: 'Twitter',
      url: 'https://twitter.com/denistiaglin',
    },
    {
      icon: telegram,
      content: 'Telegram',
      url: 'https://t.me/WeterProjectBot',
    },
    {
      icon: instagram,
      content: 'Instagram',
      url: 'https://www.instagram.com/weter_world/',
    },
    {
      icon: facebook,
      content: 'Facebook',
      url: 'https://www.facebook.com/groups/tiaglinwetergorod/',
    },
    {
      icon: linkedin,
      content: 'Linkedin',
      url: 'https://www.linkedin.com/in/denis-tiaglin-03484358/',
    },
    {
      icon: youtube,
      content: 'YouTube',
      url: 'https://www.youtube.com/c/DENISTIAGLIN',
    },
  ]
  const [investorCount, setInvestorCount] = useState(29706)

  useEffect(() => {
    const getInvestorCount = async () => {
      const count = await fetch(getApi('projects/projects/wtr/investorcount'))
        .then((data) => data.json())
        .then(({ data }) => data.count)

      setInvestorCount(count)
    }

    getInvestorCount()
  }, [])

  return (
    <>
      <Script
        id="Yandex.Metrika"
        dangerouslySetInnerHTML={{
          __html: `
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();
            for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
            k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ym(93367715, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
            });
          `,
        }}
      />

      <Script src="https://www.googletagmanager.com/gtag/js?id=G-VT2SE89FYZ" />
      <Script
        id="Google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-VT2SE89FYZ');
          `,
        }}
      />

      <Script
        id="Clarity"
        dangerouslySetInnerHTML={{
          __html: `
            (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "gxm7qlrout");
          `,
        }}
      />

      <Header />
      <main>
        <Main
          selectedNews={selectedNews}
          changeSelectedNews={{ prevNews, nextNews }}
          openNewsModal={openNewsModal}
          openPresentationModal={openPresentationModal}
          newsMain={news}
          title={'W.E.T.E.R'}
          projectType={'weter'}
        />
        <Schema />
        <Technology />

        <Map mapsProp={maps} />
        <Important
          cons={{
            title: 'important.cons.title',
            point1: 'important.cons.point1',
            point2: 'important.cons.point2',
            point3: 'important.cons.point3',
            bgImage: (
              <StaticImage
                placeholder="blurred"
                src="../../../../assets/images/weter/Bad.jpg"
                alt="cons"
              />
            ),
          }}
          pros={{
            title: 'important.pros.title',
            point1: 'important.pros.point1',
            point2: 'important.pros.point2',
            point3: 'important.pros.point3',
            bgImage: (
              <StaticImage
                placeholder="blurred"
                src="../../../../assets/images/weter/Good.jpg"
                alt="cons"
              />
            ),
          }}
        />
        <OldSolutions />
        <Comparison />
        <VideoPresentation openPresentationModal={openPresentationModal} />

        <Opportunities />
        <Visualization useVisualizationHook={useWeterVisualizations} />
        <Author />
        <Patents patents={patents} />
        <Goal />

        <News setSelectedNews={setSelectedNews} openNewsModal={openNewsModal} newsHook={news} />
        <Streams streams={[streams.a, streams.b, streams.c]} />
        <StockCharts name={'enter.wtr.title'} title={'enter.wtr.title'} />

        <Invest countNumber={investorCount} project={'weter'} />
        <Committee />
        <Future theme={'dark'} />
      </main>
      <Footer project={'weter'} />
      <Modal className={styles.video} isOpen={presentationModal} onRequestClose={() => setPresentationModal(false)}>
        <EmbedVideo youtubeID={videos[langCode] || videos.en} />
      </Modal>
      <Modal className={styles.news} isOpen={newsModal} onRequestClose={() => setNewsModal(false)}>
        {news[selectedNews].mainImage ? (
          <GatsbyImage image={news[selectedNews].mainImage} alt="" />
        ) : (
          <StaticImage
            src="../../../../assets/images/weter/newsPoster.jpg"
            alt="WETER LOGO"
            placeholder="blurred"
            aspectRatio={1.77} // 16 / 9
          />
        )}
        <div className={styles.text}>
          <Typography
            className={styles.title}
            size={32}
            dangerouslySetInnerHTML={{ __html: getContent(news[selectedNews].title) }}
          />
          <Typography
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: getContent(selectedDescription).replaceAll(/<img[^>]*>|<hr[^>]*>[\s\S]*/gm, ''),
            }}
          />
        </div>
        <div className={styles.links}>
          {linksData.map((link) => (
            <a href={link.url} key={link.content} className={styles.item}>
              <img className={styles.icon} src={link.icon} alt={link.content} />
              <Typography disableLocalize className={styles.social} size={16} weight={500}>
                {link.content}
              </Typography>
            </a>
          ))}
        </div>
      </Modal>
      {/* <Modal className={styles.form} isOpen={formModal} onRequestClose={() => setFormModal(false)}>
        <div className={styles.wrapper}>
          <Typography className={styles.title} size={24} weight={700} disableLocalize>
            Оставьте свои данные
          </Typography>
          <Typography className={styles.subtitle} disableLocalize size={20}>
            Наши специалисты свяжутся с Вами
          </Typography>
          <div className={styles.inputs}>
            <PhoneInput value={phoneValue} country={userCountry} onChange={(phone) => setPhoneValue(phone)} />
            <Input placeholder="Ваше имя" />
          </div>
          <Button onClick={handleForm}>Отправить</Button>
        </div>
      </Modal> */}
    </>
  )
}
