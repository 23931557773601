import React from 'react'

import livingSpace from '../../../../../../assets/images/weter/living-spaces.png'
import administative from '../../../../../../assets/images/weter/administrative-premises.png'
import dataCenter from '../../../../../../assets/images/weter/data-centers.png'
import hydroponicaAk47 from '../../../../../../assets/images/weter/hydroponic-farms.png'

import { Wrapper } from '@components/App/Wrapper'

import * as styles from './Opportunities.module.scss'
import { Typography } from '@UIKit'

const stubs = [
  { urlImg: livingSpace, title: 'opportunities.1' },
  { urlImg: administative, title: 'opportunities.2' },
  { urlImg: dataCenter, title: 'opportunities.3' },
  { urlImg: hydroponicaAk47, title: 'opportunities.4' },
]

export const Opportunities: React.FC = () => (
  <section>
    <Wrapper>
      <Typography className={styles.title} size={64} weight={500}>
        opportunities.title
      </Typography>
      <div className={styles.grid}>
        {stubs.map(({ urlImg, title }) => (
          <div key={title} className={styles.wrap}>
            <img className={styles.img} src={urlImg} alt="title" />
            <div className={styles.name}>
              <Typography size={20}>{title}</Typography>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  </section>
)
