import React from 'react'

import shields from '../../../../../../assets/images/weter/shield.jpg'
import flow from '../../../../../../assets/images/weter/flow.jpg'
import module from '../../../../../../assets/images/weter/module.jpg'

import { Wrapper } from '@components/App/Wrapper'
import { Card } from '@components/App/Card'

import * as styles from './Technology.module.scss'
import { Typography } from '@UIKit'

export const Technology: React.FC = () => (
  <section id="Perks">
    <Wrapper>
      <Typography className={styles.title} size={64} weight={500}>
        technology.title
      </Typography>

      <div className={styles.grid}>
        <Card alignText={'center'} image={shields} subtitle="technology.shields.description" />
        <Card alignText={'center'} image={flow} subtitle="technology.flow.description" />
        <Card alignText={'center'} image={module} subtitle="technology.module.description" />
      </div>
    </Wrapper>
  </section>
)
