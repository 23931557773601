import React, { useState } from 'react'
import cn from 'classnames'

import { Link, Typography } from '@UIKit'
import { Wrapper } from '@components/App/Wrapper'

import ArrowIcon from '../../../../../../assets/images/weter/arrowIcon.inline.svg'

import * as styles from './Goal.module.scss'

const stubs = [
  { time: 'roadmap.step1.date', title: 'roadmap.step1.title' },
  { time: 'roadmap.step2.date', title: 'roadmap.step2.title' },
  { time: 'roadmap.step3.date', title: 'roadmap.step3.title' },
  { time: 'roadmap.step4.date', title: 'roadmap.step4.title' },
]

// TODO: rename to RoadMap
export const Goal: React.FC = () => {
  const [animated, setAnimated] = useState(false)
  return (
    <section className={styles.root}>
      <Wrapper>
        <div>
          <Typography className={styles.title} size={64} weight={500} color="white">
            roadmap.title
          </Typography>

          <Typography className={styles.subtitle} size={20}>
            roadmap.description
          </Typography>

          <div className={styles.roadMap}>
            <Link
              className={cn(styles.link, animated && styles.animated)}
              to="https://my.tiaglin.com/project/wtr/development"
              allowEn
              target="_blank"
              color="primary"
              onAnimationEnd={() => setAnimated(false)}
            >
              <Typography size={20} color="inherit" missOnAlias>
                roadmap.read
              </Typography>
              <ArrowIcon className={styles.icon} width={16} height={16} />
            </Link>
          </div>
          <div className={styles.grid}>
            {stubs.map(({ time, title }) => (
              <div className={styles.step} key={title} onClick={() => setAnimated(true)}>
                <Typography className={styles.time} size={24} weight={700} color="inherit">
                  {time}
                </Typography>
                <Typography className={styles.description} size={20} color="inherit">
                  {title}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </section>
  )
}
