import React from 'react'
import cn from 'classnames'

import * as styles from './SchemaBlock.module.scss'

interface SchemaBlockProps {
  className: string
  image: React.ReactNode
  text: React.ReactNode
  textClassName: string
}

export const SchemaBlock: React.FC<Partial<SchemaBlockProps>> = ({ className, image, text, textClassName }) => {
  return (
    <div className={cn(styles.root, className)}>
      <div className={styles.image}>{image}</div>
      <div className={cn(styles.text, textClassName)}>{text}</div>
    </div>
  )
}
