import React from 'react'

import cn from 'classnames'

import * as styles from './Card.module.scss'
import { Typography } from '@UIKit'

interface CardProps {
  image: string,
  title?: string,
  subtitle?: string,
  className?: string,
  alignText?: 'left' | 'center' | 'right',
}

export const Card: React.FC<CardProps> = ({
  image,
  title,
  subtitle,
  className,
  alignText = 'left',
}) => (
  <div className={cn(styles.root, styles[alignText], className)}>
    <div className={styles.image}>
      <img src={image} alt={title} />
    </div>
    <div className={styles.description}>
      <Typography className={styles.title} size={24} weight={700}>{title}</Typography>
      <Typography size={20}>{subtitle}</Typography>
    </div>
  </div>
)
