import React from 'react'

import weter1 from '../../../../../../assets/images/weter/comparison/weter1.svg'
import weter2 from '../../../../../../assets/images/weter/comparison/weter2.svg'
import weter3 from '../../../../../../assets/images/weter/comparison/weter3.svg'
import weter4 from '../../../../../../assets/images/weter/comparison/weter4.svg'
import weter5 from '../../../../../../assets/images/weter/comparison/weter5.svg'
import weter6 from '../../../../../../assets/images/weter/comparison/weter6.svg'
import weter7 from '../../../../../../assets/images/weter/comparison/weter7.svg'

import windTurbine1 from '../../../../../../assets/images/weter/comparison/windTurbine1.svg'
import windTurbine2 from '../../../../../../assets/images/weter/comparison/windTurbine2.svg'
import windTurbine3 from '../../../../../../assets/images/weter/comparison/windTurbine3.svg'
import windTurbine4 from '../../../../../../assets/images/weter/comparison/windTurbine4.svg'
import windTurbine5 from '../../../../../../assets/images/weter/comparison/windTurbine5.svg'
import windTurbine6 from '../../../../../../assets/images/weter/comparison/windTurbine6.svg'
import windTurbine7 from '../../../../../../assets/images/weter/comparison/windTurbine7.svg'

import { Carousel, Typography } from '@UIKit'
import { Wrapper } from '@components/App/Wrapper'
import { Card } from '@components/App/Card'

import * as styles from './OldSolutions.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

export const OldSolutions: React.FC = () => {
  const slides = [
    {
      weter: {
        image: weter1,
        subtitle: 'old.solutions.weter.title.description1',
      },
      other: {
        image: windTurbine1,
        subtitle: 'old.solutions.others.description1',
      },
    },
    {
      weter: {
        image: weter2,
        subtitle: 'old.solutions.weter.title.description2',
      },
      other: {
        image: windTurbine2,
        subtitle: 'old.solutions.others.description2',
      },
    },
    {
      weter: {
        image: weter3,
        subtitle: 'old.solutions.weter.title.description3',
      },
      other: {
        image: windTurbine3,
        subtitle: 'old.solutions.others.description3',
      },
    },
    {
      weter: {
        image: weter4,
        subtitle: 'old.solutions.weter.title.description4',
      },
      other: {
        image: windTurbine4,
        subtitle: 'old.solutions.others.description4',
      },
    },
    {
      weter: {
        image: weter5,
        subtitle: 'old.solutions.weter.title.description5',
      },
      other: {
        image: windTurbine5,
        subtitle: 'old.solutions.others.description5',
      },
    },
    {
      weter: {
        image: weter6,
        subtitle: 'old.solutions.weter.title.description6',
      },
      other: {
        image: windTurbine6,
        subtitle: 'old.solutions.others.description6',
      },
    },
    {
      weter: {
        image: weter7,
        subtitle: 'old.solutions.weter.title.description7',
      },
      other: {
        image: windTurbine7,
        subtitle: 'old.solutions.others.description7',
      },
    },
  ]

  return (
    <section className={styles.root}>
      <Wrapper maxWidth="820">
        <Typography className={styles.title} size={64} weight={500}>
          old.solutions.title
        </Typography>
      </Wrapper>

      <div className={styles.carousel}>
        <div className={styles.background}>
          <StaticImage
            className={styles.image}
            src="../../../../../../assets/images/weter/comparison/background.png"
            alt="background"
            placeholder="none"
          />
        </div>
        <Carousel
          slidesPerView={1.8}
          navigation
          pagination={{
            clickable: true,
          }}
          slides={slides.map(({ weter, other }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.slide}>
              <Card image={other.image} subtitle={other.subtitle} alignText="center" />
              <div className={styles.divider} />
              <Card image={weter.image} subtitle={weter.subtitle} alignText="center" />
            </div>
          ))}
        />
      </div>
    </section>
  )
}
