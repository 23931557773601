import React, { useState } from 'react'
import cn from 'classnames'

import crossSectionViewMp4 from '@assets/videos/weter/CrossSectionView.mp4'
import crossSectionViewWebm from '@assets/videos/weter/CrossSectionView.webm'
import crossSectionLine from '@assets/images/weter/crossSectionLine.png'
import crossSectionHexagon from '@assets/images/weter/crossSectionHexagon.png'
import building from '@assets/images/weter/building.png'
import platform from '@assets/images/weter/platform.png'
import turbine from '@assets/images/weter/turbine.png'
import shieldBackground from '@assets/images/weter/shieldBackground.png'
import shieldForeground from '@assets/images/weter/shieldForeground.png'
import HandPointer from '@assets/images/weter/handPointer.inline.svg'

import { Hexagon, Typography } from '@UIKit'
import { Wrapper } from '@components/App/Wrapper'

import * as styles from './Schema.module.scss'
import { SchemaBlock } from './SchemaBlock/SchemaBlock'
import { getTranslate } from 'react-localize-alias'

export const Schema: React.FC = () => {
  const [explodedSchema, setExplodedSchema] = useState(false)
  const [animationStart, setAnimationStart] = useState(false)
  const [buildingText, setBuildingText] = useState(true)

  const toggleSchema = () => {
    if (!animationStart) {
      setAnimationStart(true)

      setExplodedSchema(!explodedSchema)

      setTimeout(() => {
        setBuildingText(!buildingText)
      }, 750)

      setTimeout(() => {
        setAnimationStart(false)
      }, 1500)
    }
  }

  const crossSectionViewBlock = {
    className: cn(styles.top, explodedSchema && styles.fade),
    image: (
      <>
        <video className={styles.crossSectionView} autoPlay playsInline loop muted>
          <source src={crossSectionViewWebm} type="video/webm" />
          <source src={crossSectionViewMp4} type="video/mp4" />
        </video>
        <img
          className={styles.crossSectionHexagon}
          src={crossSectionHexagon}
          alt={getTranslate('schema.top.title') as string}
        />
        <img
          className={styles.crossSectionLine}
          src={crossSectionLine}
          alt={getTranslate('schema.cross.section.line') as string}
        />
      </>
    ),
    text: (
      <>
        <Typography size={24} weight={700}>
          schema.top.title
        </Typography>
        <Typography size={20}>schema.top.description</Typography>
      </>
    ),
    textClassName: cn(animationStart && styles.fade),
  }

  const isometricViewBlocks = [
    {
      className: cn(styles.platform, explodedSchema && styles.exploded),
      image: <img src={platform} alt={getTranslate('schema.isometric.platform.title') as string} />,
      text: (
        <>
          <Typography size={24} weight={700}>
            schema.isometric.platform.title
          </Typography>
          <Typography size={20}>schema.isometric.platform.description</Typography>
        </>
      ),
      textClassName: cn(animationStart && styles.fade, !explodedSchema && styles.hide),
    },

    {
      className: cn(styles.turbine, explodedSchema && styles.exploded),
      image: <img src={turbine} alt={getTranslate('schema.isometric.turbine.title') as string} />,
      text: (
        <>
          <Typography size={24} weight={700}>
            schema.isometric.turbine.title
          </Typography>
          <Typography size={20}>schema.isometric.turbine.description</Typography>
        </>
      ),
      textClassName: cn(animationStart && styles.fade, !explodedSchema && styles.hide),
    },
    {
      className: cn(styles.building),
      image: (
        <>
          <img src={building} alt={getTranslate('schema.isometric.building.title') as string} />
        </>
      ),
      text: (
        <>
          {buildingText ? (
            <>
              <Typography size={24} weight={700}>
                schema.isometric.compiled.title
              </Typography>
              <Typography size={20}>schema.isometric.compiled.description</Typography>
            </>
          ) : (
            <>
              <Typography size={24} weight={700}>
                schema.isometric.building.title
              </Typography>
              <Typography size={20}>schema.isometric.building.description</Typography>
            </>
          )}
        </>
      ),
      textClassName: cn(animationStart && styles.fade),
    },
    {
      className: cn(styles.shields, explodedSchema && styles.exploded),
      image: (
        <>
          <img src={shieldBackground} alt={getTranslate('schema.isometric.shields.title') as string} />
          <img
            className={styles.shieldForeground}
            src={shieldForeground}
            alt={getTranslate('schema.isometric.shields.title') as string}
          />
        </>
      ),
      text: (
        <>
          <Typography size={24} weight={700}>
            schema.isometric.shields.title
          </Typography>
          <Typography size={20}>schema.isometric.shields.description</Typography>
        </>
      ),
      textClassName: cn(animationStart && styles.fade, !explodedSchema && styles.hide),
    },
  ]

  return (
    <section className={styles.root} id="HowItWork">
      <Wrapper maxWidth="820">
        <Typography className={styles.title} size={64} weight={500}>
          schema.title
        </Typography>
      </Wrapper>

      <div className={styles.schema} onClick={toggleSchema}>
        <div className={styles.top}>
          <SchemaBlock {...crossSectionViewBlock} />
        </div>

        <div className={styles.isometric}>
          {isometricViewBlocks.map((block, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SchemaBlock key={index} {...block} />
          ))}
        </div>
      </div>

      <div className={styles.slider} onClick={toggleSchema} onTouchStart={toggleSchema}>
        <div className={styles.track} />
        <Hexagon className={cn(styles.thumb, explodedSchema && styles.exploded)}>
          <HandPointer className={cn(styles.icon, animationStart && styles.fade)} />
        </Hexagon>
      </div>
    </section>
  )
}
