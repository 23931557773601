import React from 'react'

import cn from 'classnames'

import * as styles from './ComparisonCard.module.scss'
import { Typography } from '@UIKit'

interface CardProps {
  image?: JSX.Element
  iconUrl: string
  index: number
  title: string
}

export const ComparisonCard: React.FC<CardProps> = ({ image, iconUrl, index, title }) => (
  <div className={cn(styles.root)}>
    <div className={styles.background}>{image}</div>
    <div className={styles.content}>
      <div className={styles.info}>
        <div className={styles.percentage}>
          <Typography className={styles.number} size={64} weight={700} disableLocalize>
            {index}
          </Typography>
          <Typography size={20} weight={900} disableLocalize>
            %
          </Typography>
        </div>
        <Typography size={20}>comparison.card</Typography>
      </div>

      <div className={styles.building}>
        <img className={styles.image} src={iconUrl} alt={iconUrl} />

        <Typography size={24} weight={700}>
          {title}
        </Typography>
      </div>
    </div>
  </div>
)
