import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './Comparison.module.scss'

import ManamaIcon from '../../../../../../assets/images/weter/manamaEfficiency.png'
import ChinaIcon from '../../../../../../assets/images/weter/chinaEfficiency.png'
import WeterIcon from '../../../../../../assets/images/weter/weterEfficiency.png'

import { ComparisonCard } from '@components/weter'
import { Wrapper } from '@components/App/Wrapper'
import { Typography } from '@UIKit'

export const Comparison: React.FC = () => (
  <section>
    <Wrapper>
      <Typography className={styles.title} size={64} weight={500}>
        comparison.title
      </Typography>

      <div className={styles.grid}>
        <ComparisonCard
          image={
            <StaticImage src="../../../../../../assets/images/weter/manama.jpg" alt="manama" placeholder="blurred" />
          }
          iconUrl={ManamaIcon}
          index={3}
          title="comparison.1.title"
        />
        <ComparisonCard
          image={
            <StaticImage src="../../../../../../assets/images/weter/china.jpg" alt="china" placeholder="blurred" />
          }
          iconUrl={ChinaIcon}
          index={10}
          title="comparison.2.title"
        />
        <ComparisonCard
          image={
            <StaticImage
              src="../../../../../../assets/images/weter/weterProject.jpg"
              alt="weterProject"
              placeholder="blurred"
            />
          }
          iconUrl={WeterIcon}
          index={200}
          title="comparison.3.title"
        />
      </div>
    </Wrapper>
  </section>
)
