import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { Wrapper } from '@components/App/Wrapper'
import { Button, Link, Typography } from '@UIKit'

import * as styles from './Invest.module.scss'

const steps = ['invest.step.1', 'invest.step.2']
interface InvestProps {
  openFormModal?: () => void
  coin?: React.ReactNode
  countNumber?: number
}
const coinImage = (
  <StaticImage
    className={styles.coin}
    placeholder="blurred"
    src={'../../../../../../assets/images/weter/coin.png'}
    alt="coin"
  />
)

export const Invest: React.FC<InvestProps> = ({ openFormModal, coin = coinImage, countNumber }) => {
  return (
    <section>
      <Wrapper>
        <div className={styles.wrapper}>
          <div>
            <Typography className={styles.title} size={64} weight={500} color="white">
              invest.title
            </Typography>
            {countNumber !== undefined ? (
              <div className={styles.counter}>
                <Typography className={styles.number} size={152} disableLocalize>
                  {countNumber}
                </Typography>
                <Typography size={24}>invest.counter.investors</Typography>
              </div>
            ) : null}

            <ol className={styles.list}>
              {steps.map((item, index) => (
                <li className={styles.item} key={item}>
                  <Typography size={20} weight={700} disableLocalize>
                    {index + 1}
                  </Typography>
                  <Typography size={20}>{item}</Typography>
                </li>
              ))}
            </ol>
            <div className={styles.actions}>
              <Link to="https://my.tiaglin.com/{code}/auth" allowEn target="_blank">
                <Button className={styles.btn} fullWidth variant="primary" onClick={openFormModal}>
                  <Typography size={20} color="white">
                    invest.register
                  </Typography>
                </Button>
              </Link>
              <Link to="https://t.me/WETER_SUPPORT" allowEn target="_blank">
                <Button className={styles.button} fullWidth variant="secondary">
                  <Typography size={20} color="white">
                    invest.consult
                  </Typography>
                </Button>
              </Link>
            </div>
          </div>
          <div>{coin}</div>
        </div>
      </Wrapper>
    </section>
  )
}
