// extracted by mini-css-extract-plugin
export var title = "Goal-module--title--f0f3f";
export var subtitle = "Goal-module--subtitle--3b3af";
export var roadMap = "Goal-module--roadMap--17264";
export var grid = "Goal-module--grid--b0eb0";
export var step = "Goal-module--step--cc081";
export var link = "Goal-module--link--03198";
export var animated = "Goal-module--animated--730fb";
export var blink = "Goal-module--blink--925b3";
export var icon = "Goal-module--icon--2c445";
export var time = "Goal-module--time--e3050";
export var description = "Goal-module--description--aeca2";